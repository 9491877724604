import { useState } from "react";
import { Container, Logo, Form, FormTitle } from "./styles";
import logoImg from "../../assets/LI02.jpg";
import Input from "../Input";
import Button from "../Button";
import axios, { AxiosResponse } from "axios";

export interface IFormularioProps {}

export default function Formulario() {
  const [passw, setPassw] = useState("");
  const [confPassw, setConfPassw] = useState("");
  function handlePasswChange(event: any) {
    setPassw(event.target.value);
  }
  function handleConfPasswChange(event: any) {
    setConfPassw(event.target.value);
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (passw !== confPassw) {
      alert("As senhas não conferem");
      return;
    }
    if (passw.length < 6) {
      alert("A senha deve conter mais de 6 caracteres");
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("p") as string;
    if (!!token) {
      //const apiUrl: string = "http://localhost:3030/a/auth/recebpassw";
      // para emparelhar os commits
      const apiUrl: string = "https://node.rc12.tech/a/auth/recebpassw";
      const postData: object = {
        passw: passw,
      };
      try {
        const response: AxiosResponse = await axios.post(apiUrl, postData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Email alterado com sucesso!!!");
        alert("Email alterado com sucesso!!!");
      } catch (error) {
        alert("A alteração não teve sucesso!!!, Tente novamente.");
        //console.log(error);
      }
    } else {
      alert("Parametros incompletos!!!");
      return;
    }
  }
  return (
    <>
      <Container>
        <Logo>
          <img src={logoImg} alt="Nova Senha" />
        </Logo>
        <h3> </h3>
        <Form onSubmit={handleSubmit}>
          <FormTitle>Alterar a senha.</FormTitle>
          <Input
            required
            type="password"
            placeholder="senha"
            value={passw}
            onChange={handlePasswChange}
          />
          <Input
            required
            type="password"
            placeholder="confirm. senha"
            value={confPassw}
            onChange={handleConfPasswChange}
          />
          <Button type="submit">Alterar</Button>
        </Form>
      </Container>
      ;
    </>
  );
}
