import React from "react";
import Formulario from "./components/Formulario";
import { GlobalStyles } from "./styles/GlobalStyles";

function App() {
  return (
    <>
      <GlobalStyles />
      <Formulario />
    </>
  );
}
export default App;
