import styled from "styled-components";

export const Container = styled.div`
  height: 100vh; // para ele ocupar toda a altura da tela
  display: flex;
  flex: 1; // para ocupar toda a região datela possível
  flex-direction: column;
  justify-content: top;
  align-items: center;
  background-color: #555;
  color: #ddddde;
  height: 100vh;
`;

export const Logo = styled.div`
  display: flex;
  alin-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  h3 {
    color: #ddd;
  }
  img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
  }
`;

// ${props => props.theme.colors.white}

export const Form = styled.form`
  width: 300px;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #777;
`;

export const FormTitle = styled.h2`
  &:after {
    content: "";
    display: block;
    width: 60px;
    border-bottom: 5px solid #0b5a93;
    margin-bottom: 10px;
  }
`;
