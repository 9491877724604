import styled from "styled-components";

export const Container = styled.button`
  width: 100%;
  margin: 7px 0;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid  #ffffff;
  font-weight: bold;
  color: #ffffff;
  font-size 15px;
  background-color:transparent ;

  transity:opacity .3s;

  &:hover {
    background: #0b5a93;
    box-shadow 0 0 5px 5px #0b5a93;
  }
`;
